import React from 'react';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PageHeading from '../../components/PageHeading/PageHeading';
import { Container, Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { useQuery } from 'react-query';
import styles from '../../css/tuyenUyCommunication.module.css';

const Broadcasts = () => {
  const { isLoading, error, data } = useQuery(
    'getTuyenUyPosts',
    async () => {
      const { data } = await axios.get(
        'https://cors-veym.herokuapp.com/https://cms.veym.net/items/tuyen_uy_communication?sort=-date'
      );

      return data.data;
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <Layout>
      <Helmet
        title="VEYM | Tuyên Úy Communication"
        meta={[
          {
            name: 'description',
            content: 'Messages from Cha Tổng Tuyên Úy to Các Cha Tuyên Úy',
          },
        ]}
      />
      <PageHeading>Tuyên Úy Communication</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/news">News</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Tuyên Úy Communication</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <br />
      <Container>
        <h4 className={styles.pageDescription}>
          Messages from Cha Tổng Tuyên Úy to Các Cha Tuyên Úy
        </h4>
        {isLoading && (
          <div className={styles.loading}>
            <Spinner animation="border" variant="secondary" /> Loading...
          </div>
        )}
        {error && (
          <div>
            Unable to load posts. Please refresh the page or try again later.
          </div>
        )}
        {data &&
          data.map(post => (
            <div key={post.id} className={styles.tuyenUyPost}>
              <h5>{post.title}</h5>
              <p className={styles.date}>
                {moment(post.date).format('MMMM Do, YYYY')}
              </p>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
          ))}
        {data && data.length === 0 && (
          <div>
            <span role="img" aria-label="bubble">
              💬
            </span>{' '}
            No posts found.{' '}
            <span role="img" aria-label="bubble">
              💬
            </span>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default Broadcasts;
